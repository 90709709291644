/* --- Fonts --- */
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap');
@import "leaflet/dist/leaflet.css";



/* --- Variables --- */
:root {
  --light: #EBEBEB;
  --dark: #0E1116;
  --shade: #171b21;
  --overlay: rgba(162, 120, 0255, 0.3);
  --accent: #a278ff;
}



/* --- Misc. --- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--dark);
  transition: all 0.2s;
  color: var(--light);
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  text-align: center;
}

.text {
  line-height: 1.5;
}

h1,h2 {
  font-family: 'Amatic SC', cursive;
}



/* --- Navigation --- */
#header {
  padding: 16px 10%;
  position: fixed;
  transition: all 0.2s;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10000;
}

#header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#logo {
  font-size: 42px;
  font-weight: 700;
  font-family: 'Amatic SC', cursive;
  text-decoration: none;
  color: var(--light);
  transition: all 0.2s;
}

#logo span {
  color: var(--accent);
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-menu a {
  text-decoration: none;
  color: var(--light);
  transition: all 0.2s;
  opacity: 0.8;
  padding: 2px;
  margin-left: 32px;
}

.nav-menu a:hover {
  opacity: 1;
}

.scrolled {
  background-color: var(--dark);
}



/* --- Mobile Nav --- */
.burger {
  display: none;
  cursor: pointer;
  z-index: 1000;
}

.burger div {
  width: 20px;
  height: 2px;
  margin: 4px;
  background-color: var(--light);
  transition: all 0.2s;
}



/* --- Home --- */
#home {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px 10%;
  height: 100vh;
}

#home .container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background, #tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

h1 {
  font-size: 68px;
  margin-bottom: 8px;
}

#home .text {
  font-size: 16px;
  width: 60%;
  margin-bottom: 24px;
  opacity: 0.9;
}

.btn {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
}



/* --- About --- */
#about {
  padding: 96px 10% 40px;
}

h2 {
  font-size: 42px;
  margin-bottom: 32px;
}

#about .container {
  display: grid;
  grid-template-columns: 50fr 50fr;
  grid-template-rows: auto;
  gap: 32px;
  justify-content: center;
  align-items: center;
}

#about img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  background-color: var(--shade);
  border: 2px solid var(--shade);
  border-radius: 5px;
  opacity: 0.7;
}

#about a {
  text-decoration: none;
}

#about a:hover {
  text-decoration: underline;
}

.accentColor {
  color: var(--accent);
}



/* --- Categories --- */
#category {
  padding: 96px 10% 40px;
}

h2 {
  font-size: 42px;
  margin-bottom: 32px;
}

#category .container {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  grid-template-rows: auto auto;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
}

#category img {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0.9;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid var(--shade);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  opacity: 0;
  transition: 0.4s;
  background-color: var(--overlay);
  border-radius: 5px;
}

.card:hover .overlay {
  opacity: 1;
}

.card a {
  color: var(--light);
}



/* --- Social --- */
#social {
  padding: 96px 10% 40px;
}

#social h2 {
  margin-bottom: 8px;
}

#social .container {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  gap: 16px;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
}

#social .text {
  margin-bottom: 32px;
}

.insta-post {
  position: relative;
  height: 263px;
}

#social img {
  height: 100%;
  width: 100%;
  margin: 0;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid var(--shade);
  opacity: 0.8;
}



/* --- Footer --- */
footer {
  padding: 32px 10%;
  border-top: 2px solid var(--shade);
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

footer nav a {
  font-weight: 600;
  font-size: 0.8em;
  text-decoration: none;
  color: var(--light);
  padding: 2px;
  margin: 0 16px;
  transition: all 0.2s;
  text-transform: uppercase;
  line-height: 2;
}

.copyright {
  font-size: 0.8em;
  margin-right: 16px;
  opacity: 0.8;
}



/* --- Contact page --- */
#contact {
  padding: 96px 20% 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#contact h2 {
  margin-bottom: 8px;
}

#contact .text {
  margin-bottom: 32px;
}

#contact form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

form .flex {
  width: 100%;
  display: flex;
}

#contact input[type="text"],textarea {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 1em;
  width: 100%;
  padding: 18px;
  margin-bottom: 4px;
  border-radius: 5px;
  border: none;
  background-color: var(--shade);
  color: var(--light);;
  transition: all 0.2s;
}

input[name="name"] {
  margin-right: 4px;
}

form textarea {
  height: 15em;
  resize: vertical;
  min-height: 10em;
  max-height: 40em;
  display: block;
}

form button {
  width: auto;
  cursor: pointer;
  margin-top: 28px;
  background-color: transparent;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1em;
}

.btn {
  text-decoration: none;
  border: none;
  border-radius: 5px;
  background-color: var(--accent);
  border: 2px solid var(--accent);
  color: var(--dark);
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  padding: 16px 32px;
}

.btn:hover{
  background-color: transparent;
  color: var(--accent);
  transition: all 0.2s;
}

::-webkit-input-placeholder {
  font-weight: 300;
  color: var(--light);
  transition: color 0.2s;
  font-family: 'Nunito', sans-serif;
  opacity: 0.75;
}

::-moz-placeholder {
  font-weight: 300;
  color: var(--light);
  transition: color 0.2s;
  font-family: 'Nunito', sans-serif;
  opacity: 0.75;
}

::-ms-input-placeholder {
  font-weight: 300;
  color: var(--light);
  transition: color 0.2s;
  font-family: 'Nunito', sans-serif;
  opacity: 0.75;
}



/* --- Search Page --- */
#search {
  padding: 82.5px 0 0;
  transition: all 0.2s;
  min-height: 100vh;
  margin: 0 10%;
}

.leaflet-container {
  width: 100%;
  background-color: var(--dark);
  border: 2px solid var(--shade);
}

#search .grid {
  width: 100%;
  display: grid;
  grid-template-columns: 25fr 25fr 35fr 15fr;
  grid-template-rows: auto;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

#search .flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 80px 0;
}

.relative {
  position: relative;
  width: 100%;
}

#search input[type="text"] {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding: 18px 18px 18px 56px;
  border-radius: 5px;
  background-color: var(--shade);
  border: none;
  color: var(--light);
  transition: all 0.2s;
}

#search input:focus{
  outline: none;
}

#search .fa-magnifying-glass {
  position: absolute;
  color: var(--light);
  left: 0;
  top: 0;
  font-size: 18px;
  opacity: 0.5;
  padding: 20px 22px;
}

.sort {
  width: 100%;
  position: relative;
}

.fa-sort-down {
  z-index: 2;
  position: absolute;
  color: var(--light);
  left: 0;
  top: 0;
  font-size: 18px;
  opacity: 0.5;
  padding: 16px 24px;
}

.style-select {
  display: -ms-grid;
  display: grid;
  grid-template-areas: "select";
  background-color: var(--shade);
  transition: all 0.2s;
  border-radius: 5px;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 18px 18px 18px 56px;
  margin: 0;
  border: none;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #999;
  background-color: var(--shade);
  border-radius: 5px;
  z-index: 1;
  cursor: inherit;
  line-height: inherit;
}

select::-ms-expand {
  display: none;
}

.distance-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.pill {
  background: var(--shade);
  color: #ccc;
  border: 1px solid #2a2a2f;
  border-radius: 5px;
  padding: 0.4rem 1.2rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.25s ease;
}

.pill:hover {
  background-color: #a278ff;
  color: #0b0b0d;
  border-color: #a278ff;
  box-shadow: 0 0 6px #a278ff44;
}

.pill.active {
  background-color: #ff4f5a;
  color: #fff;
  border-color: #ff4f5a;
  box-shadow: 0 0 8px #ff4f5a88;
}

#search .btn {
  align-self: center;
  cursor: pointer;
  width: 100%;           
}

#search h2 {
  text-align: left;
  margin-bottom: 16px;
}

#search .container {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  grid-template-rows: auto auto;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-bottom: 96px;
}

.location-card {
  background-color: var(--shade);
  color: var(--light);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.location-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: block;
}

.location-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.location-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.location-meta {
  color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.icon {
  color: var(--accent);
}

.location-description {
  text-align: left;
  color: var(--light);
  line-height: 1.4;
  max-height: 5.5em; /* ~4 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.5;
}

.location-link {
  cursor: pointer;
  align-self: flex-start;
  background-color: var(--light);
  color: var(--shade);
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  transition: background-color 0.2s ease-in-out;
}

.location-link:hover {
  background-color: var(--accent);
}



/* --- Media Queries --- */
@media screen and (max-width: 1024px) {
  .nav-menu {
    transform: translateY(-100%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: var(--shade);
    flex-direction: column;
    justify-content: center;
    transition: transform .45s cubic-bezier(.45,0,0,1);
  }

  .expanded {
    transform: translateY(0);
  }

  .nav-menu a {
    margin: 0;
    padding: 18px;
  }

  #header {
    padding: 16px 5%;
  }

  #toggle {
    padding: 18px;
    margin: 0;
    display: inline-block;
  }

  .burger {
    display: block;
  }

  #home {
    padding: 16px 5%;
  }

  #home .text {
    width: 65%;
  }

  #about {
    padding: 96px 5% 40px;
  }

  #about .container {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }

  #about img {
    height: 300px;
  }

  #category {
    padding: 96px 5% 40px;
  }

  #social {
    padding: 96px 5% 40px;
  }

  #category .container {
    grid-template-columns: 50fr 50fr;
    grid-template-rows: auto auto auto;
  }

  #social .container {
    grid-template-columns: 50fr 50fr;
    grid-template-rows: auto auto;
  }
  
  .insta-post {
    height: 200px;
  }

  footer {
    padding: 32px 5%;
  }

  #search {
    margin: 0 5%;
  }

  #search .grid {
    grid-template-columns: 50fr 50fr;
  }

  #search .container {
    grid-template-columns: 50fr 50fr;
    grid-template-rows: auto auto auto;
  }

  #search img {
    height: 254px;
  }
}

@media screen and (max-width: 766px) {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
  }

  #home .text {
    width: 100%;
  }
  
  .search {
    width: 100%;
  }

  #category .container {
    grid-template-columns: 100fr;
    grid-template-rows: auto;
  }

  #social .container {
    grid-template-columns: 100fr;
    grid-template-rows: auto;
  }

  footer {
    padding: 32px 10%;
    flex-direction: column;
  }

  .copyright {
    margin: 0 0 24px;
  }

  #contact {
    padding: 96px 5% 40px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .relative {
    width: 100%;
  }

  #search .grid {
    grid-template-columns: auto;
  }

  #search .container {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: 4vw;
  }

  #search img {
    height: 238px;
  }
}

@media screen and (min-width: 1920px) {
  #header {
    padding: 16px 15%;
  }

  #home {
    padding: 16px 15%;
  }

  #about {
    padding: 96px 15% 40px;
  }

  #category {
    padding: 96px 15% 40px;
  }

  #social {
    padding: 96px 15% 40px;
  }

  footer {
    padding: 32px 15%;
  }

  #contact {
    padding: 96px 30% 40px;
  }

  #search {
    margin: 0 15%;
  }
}

@media screen and (min-width: 2560px) {
  #header {
    padding: 16px 20%;
  }

  #home {
    padding: 16px 20%;
  }

  #about {
    padding: 96px 20% 40px;
  }

  #category {
    padding: 96px 20% 40px;
  }

  #social {
    padding: 96px 20% 40px;
  }

  footer {
    padding: 32px 20%;
  }

  #contact {
    padding: 96px 31% 40px;
  }

  #search {
    margin: 0 20%;
  }
}

